<template>
  <div>
    <b-card>
      <b-card-header>
        <div class="text-primary h2">
          <feather-icon
            icon="StarIcon"
            size="25"
            class="mr-0 mr-sm-50"
            variant="primary"
          />
          <span class="font-weight-bold">{{ $t(elementName + '.name') }}</span>
        </div>
      </b-card-header>
      <b-card-body>

        <b-row>
          <!-- Right Col: Table -->
          <b-col
            cols="12"
            xl="6"
          >
            <table class="mt-2 mt-xl-0 w-100">
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="BookOpenIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">{{ $t(elementName+'.object.name') }}</span>
                </th>
                <td class="pb-50">
                  {{ elementData.name }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="BookOpenIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">{{ $t(elementName+'.object.generic_name') }}</span>
                </th>
                <td class="pb-50">
                  {{ elementData.cups_name }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="BookOpenIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">{{ $t(elementName+'.object.medicine_group') }}</span>
                </th>
                <td class="pb-50">
                  {{ getTypeValue(elementData, 'medicine_group') }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="BookOpenIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">{{ $t(elementName+'.object.laboratory') }}</span>
                </th>
                <td class="pb-50">
                  {{ elementData.laboratory }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="BookOpenIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">{{ $t(elementName+'.object.healt_registry') }}</span>
                </th>
                <td class="pb-50">
                  {{ elementData.healt_registry }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="BookOpenIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">{{ $t(elementName+'.object.cum_file') }}</span>
                </th>
                <td class="pb-50">
                  {{ elementData.cups }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="BookOpenIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">{{ $t(elementName+'.object.cum_quantity') }}</span>
                </th>
                <td class="pb-50">
                  {{ elementData.cum_quantity }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="BookOpenIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">{{ $t(elementName+'.object.description') }}</span>
                </th>
                <td class="pb-50">
                  {{ elementData.description }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="BookOpenIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">{{ $t(elementName+'.object.administration_route') }}</span>
                </th>
                <td class="pb-50">
                  {{ getTypeValue(elementData, 'administration_route') }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="BookOpenIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">{{ $t(elementName+'.object.active_principle') }}</span>
                </th>
                <td class="pb-50">
                  {{ elementData.active_principle }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="BookOpenIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">{{ $t(elementName+'.object.units_of_measure') }}</span>
                </th>
                <td class="pb-50">
                  {{ getTypeValue(elementData, 'units_of_measure') }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="BookOpenIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">{{ $t(elementName+'.object.quantity') }}</span>
                </th>
                <td class="pb-50">
                  {{ elementData.quantity }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="BookOpenIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">{{ $t(elementName+'.object.reference_unit') }}</span>
                </th>
                <td class="pb-50">
                  {{ getTypeValue(elementData, 'reference_unit') }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="BookOpenIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">{{ $t(elementName+'.object.dosage_form') }}</span>
                </th>
                <td class="pb-50">
                  {{ getTypeValue(elementData, 'dosage_form') }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="BookOpenIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">{{ $t(elementName+'.object.regulated') }}</span>
                </th>
                <td class="pb-50">
                  {{ getTypeValue(elementData, 'regulated') }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="BookOpenIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">{{ $t(elementName+'.object.minimum_delivery_unit') }}</span>
                </th>
                <td class="pb-50">
                  {{ getTypeValue(elementData, 'minimum_delivery_unit') }}
                </td>
              </tr>

            </table>
          </b-col>
        </b-row>
        <b-row class="d-flex justify-content-end">
          <b-button
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            @click="router().go(-1)"
          >
            {{ $t('actions.back') }}
          </b-button>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BButton, BAvatar, BRow, BCol, BBadge,
} from 'bootstrap-vue'

import { avatarText } from '@core/utils/filter'
import router from '@/router'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar, BBadge, BCardHeader, BCardBody,
  },
  props: {
    elementData: {
      type: Object,
      required: true,
    },
    elementName: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {
      avatarText,
      resolveElementStatusVariant(status) {
        if (status === 0) return 'warning'
        return 'success'
      },
      resolveElementStatusMessage(status) {
        if (status == 0) return this.$t('user.statusValue.inactive')
        return this.$t('user.statusValue.active')
      },
      getTypeValue(element, type) {
        return element[type] ? element[type].value : ''
      },
    }
  },
  methods: {
    router() {
      return router
    },
  },
}
</script>

<style>

</style>
